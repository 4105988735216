// import React from 'react';
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { NavBar } from "./components/NavBar";
import { Home } from "./components/Home";
import { Roadmap } from "./components/Roadmap";
import { Team } from "./components/Team";
import { Mint } from "./components/Mint";
import { Lore } from "./components/Lore";
import { FAQ } from "./components/FAQ";
import { Footer } from "./components/Footer";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="mint" element={<Mint />} />
      <Route path="faq" element={<FAQ />} />
      <Route path="team" element={<Team />} />
    </Routes>
  );
}

export default App;
