import tw from "twin.macro";
import OpenseaLogo from "../../assets/opensea_logo.png";
import Looksrare from "../../assets/looksrare_logo.png";
import FooterBarcode from "../../assets/footer_barcode.png";

const Container = tw.footer`
    bg-primaryWhite
    mt-10
`;

const Bar = tw.div`
    2xl:w-3/4
    3xl:w-1/2
    w-full
    mx-auto
    flex
    flex-col
    justify-center
    items-center
    bg-primaryWhite
    md:flex-row
    md:justify-between
    p-2
    px-10
`;

const LogosContainer = tw.div`
    flex
    gap-2
`;

const Logo = tw.img`
    mb-5
    md:mb-0
    w-10
`;

const Barcode = tw.img`
    w-[280px]
`;

export function Footer() {
  return (
    <Container>
      <Bar>
        <LogosContainer>
          <a href="https://opensea.io/collection/cosmiccryptocrusade">
            <Logo src={OpenseaLogo} />
          </a>
          <a href="https://looksrare.org/collections/0xC9433cd80f6f5D4421Eb0b0c3D221A3a9Dff1B7b">
            <Logo src={Looksrare} />
          </a>
        </LogosContainer>
        <Barcode src={FooterBarcode} />
      </Bar>
    </Container>
  );
}
