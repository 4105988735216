import tw from "twin.macro";

interface ProgressLabelProps {
  title: string;
  labelValue: number;
  totalValue?: number;
  currentValue: number;
  unit: string;
  bottom?: boolean;
}

const Label = tw.div`
    px-1
    py-2
    w-max
    absolute
`;

const LabelTitle = tw.div`
    font-body
    text-[10px]
    md:text-xs
`;

const LabelValue = tw.div`
    font-header
    text-[12px]
    md:text-base
`;

function ProgressLabel(props: ProgressLabelProps) {
  let marginLeft = (props.labelValue / (props.totalValue || 1000)) * 100;
  marginLeft = Math.max(marginLeft, 2); //min margin of 2%
  marginLeft = Math.min(marginLeft, 100); //max margin of 100%

  const completed = props.currentValue >= props.labelValue;
  const labelOnRight = marginLeft <= 50;

  return (
    <Label
      style={{
        marginLeft: marginLeft + "%",
        top: props.bottom ? 0 : "auto",
        bottom: props.bottom ? "auto" : 0,
        borderColor: completed
          ? "var(--color-primary-yellow)"
          : "var(--color-primary-white)",
        color: completed
          ? "var(--color-primary-yellow)"
          : "var(--color-primary-white)",
        borderLeftWidth: props.bottom && labelOnRight ? "2px" : "0px",
        borderRightWidth: props.bottom && labelOnRight ? "0px" : "2px",
        transform:
          props.bottom && labelOnRight ? "translateX(0%)" : "translateX(-100%)",
      }}
    >
      <LabelValue>
        {props.labelValue} {props.unit}
      </LabelValue>
      <LabelTitle>{props.title}</LabelTitle>
    </Label>
  );
}

export default ProgressLabel;
