import tw from "twin.macro";
import { NavBar } from "../NavBar";
import FAQData from "./faq-data.json";
import { RiHeadphoneLine } from "react-icons/ri";
import { BsArrowRightCircle } from "react-icons/bs";
import { FAQcollapsible } from "./FAQcollapsible";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";

const Container = tw.div`
  min-h-screen
  w-full
  bg-primaryBlack
`;

const Section = tw.div`
  mx-auto
  2xl:w-3/4
  3xl:w-1/2
  flex
  flex-col
  md:flex-row
  bg-primaryBlack
  py-10
  px-6
`;

const SectionLeft = tw.div`
  flex
  flex-col
  justify-start
  items-center
  text-center
  text-primaryYellow
  md:w-1/3
  md:items-start
  md:text-left
  md:sticky
  md:top-5
  md:h-full
`;

const SectionRight = tw.div`
  text-primaryWhite
  w-full
  flex
  flex-col
  md:w-2/3
  divide-y-2
`;

const Title = tw.h1`
  text-3xl
  text-primaryYellow
  font-header
  py-6
`;

const Subtitle = tw.h3`
  text-primaryYellow
  font-header
  pb-4
  pt-10
  text-xl
`;

const CTA = tw.div`
  w-full
  text-right
  font-body
  text-primaryYellow
  flex
  justify-end
  items-center
  pt-5
  cursor-pointer
`;

const CTAText = tw.h3`
  mr-2
`;

export function FAQ() {
  function scrollToTop() {
    animateScroll.scrollToTop({
      smooth: "easeInOutQuint",
      duration: 1000,
    });
  }
  return (
    <Container>
      <NavBar blueLogo />
      <Section>
        <SectionLeft>
          <RiHeadphoneLine size={50} />
          <Title>Frequently Asked Questions</Title>
        </SectionLeft>
        <SectionRight>
          {Object.entries(FAQData).map((vals) => {
            const [title, data] = vals;
            console.log(title, data);
            return (
              <>
                <Subtitle>{title}</Subtitle>
                {data.map((entry) => (
                  <FAQcollapsible
                    question={entry.question}
                    answer={entry.answer}
                    key={entry.question}
                    image={entry.image}
                  />
                ))}
              </>
            );
          })}
          <CTA>
            <CTAText>
              <Link to="/mint" onClick={scrollToTop}>
                Got it, I want to mint now!
              </Link>
            </CTAText>
            <BsArrowRightCircle />
          </CTA>
        </SectionRight>
      </Section>
    </Container>
  );
}
