interface ComponentProps {
  value: number;
  label: string;
}

function TimerBoxComp(props: ComponentProps) {
  return (
    <div className="flex flex-col gap-2">
      <div className="h-12 w-12 xs:h-20 xs:w-20 rounded-md bg-white text-3xl xs:text-5xl font-bond flex justify-center items-center border-[3px] border-primaryYellow p-2 px-6">
        {props.value}
      </div>
      <div className="flex justify-center items-center h-5 w-12 xs:h-7 xs:w-20 rounded bg-white text-xs xs:text-md font-bond text-center border-[3px] border-primaryYellow p-2 px-6">
        {props.label}
      </div>
    </div>
  );
}

export default TimerBoxComp;
