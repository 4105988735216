import useTimer from "../../hooks/useTimer";
import TimerBoxComp from "./TimerBoxComp";

interface TimerInput {
  epoch: number;
}

function TimerComp(value: TimerInput) {
  const { day, hour, minute, second } = useTimer(value.epoch);

  return (
    <div className="flex flex-row gap-2 justify-center font-header">
      <TimerBoxComp value={day} label="DAYS" />
      <TimerBoxComp value={hour} label="HOURS" />
      <TimerBoxComp value={minute} label="MINS" />
      <TimerBoxComp value={second} label="SECS" />
    </div>
  );
}

export default TimerComp;
