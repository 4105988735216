import tw from "twin.macro";
import ProgressLabel from "./ProgressLabelComp";
import HomeData from "../Home/home-data.json";

interface ProgressBarProps {
  current: number;
  total: number;
}

const Container = tw.div`
    w-full
    md:w-3/4
    lg:w-1/2
    2xl:w-2/5
    my-10
    mx-auto
    p-6
`;

const BarBase = tw.div`
    bg-primaryWhite    
    h-[30px]
    rounded-full
    w-full
`;

const FilledBar = tw.div`
    h-[30px]
    rounded-full
`;

const LabelContainer = tw.div`
    relative
    h-[30px]
`;

function ProgressBar(props: ProgressBarProps) {
  let percentage = (props.current / props.total) * 100;
  percentage = Math.max(percentage, 4); //min 4%
  percentage = Math.min(percentage, 100); //max 100%

  return (
    <Container>
      <LabelContainer>
        <ProgressLabel
          title="Milestone 1"
          labelValue={HomeData.milestone_1_value}
          unit="ETH"
          currentValue={props.current}
        />
        <ProgressLabel
          title="Milestone 2"
          labelValue={HomeData.milestone_2_value}
          unit="ETH"
          currentValue={props.current}
        />
        <ProgressLabel
          title="Milestone 3"
          labelValue={HomeData.milestone_3_value}
          unit="ETH"
          currentValue={props.current}
        />
      </LabelContainer>
      <BarBase>
        <FilledBar
          style={{
            width: percentage + "%",
            background: "var(--color-rainbow)",
          }}
        />
      </BarBase>
      <LabelContainer>
        <ProgressLabel
          title="Funds Raised"
          labelValue={props.current}
          unit="ETH"
          currentValue={props.current}
          bottom
        />
      </LabelContainer>
    </Container>
  );
}

export default ProgressBar;
