import tw from "twin.macro";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { scroller, animateScroll } from "react-scroll";
import { useMediaQuery } from "react-responsive";
import { slide as Menu } from "react-burger-menu";
import { IconContext } from "react-icons";
import { FaTwitter } from "react-icons/fa";
import { FiYoutube } from "react-icons/fi";
import { SiNotion } from "react-icons/si";
import { FaEllipsisH } from "react-icons/fa";
import BlackCCCLogo from "../../assets/black_logo.webp";
import BlueCCCLogo from "../../assets/blue_logo.png";
import { useState } from "react";
import OpenseaLogo from "../../assets/opensea_logo.png";
import Looksrare from "../../assets/looksrare_logo.png";
import {
  connectWallet,
  getCurrentWalletBalance,
  getCurrentWalletConnected,
} from "../Mint/interact";
import { ethers } from "ethers";

interface NavbarInput {
  socials?: boolean;
  blueLogo?: boolean;
  barColor?: string;
  textColor?: string;
  activeTextColor?: string;
  gradient?: boolean;
}

// Main components
const Container = tw.div`
  w-full
`;

// Top bar
const Bar = tw.div`
  h-[40px]
  lg:h-[60px]
  flex
  flex-row
  justify-end
  items-center
  2xl:w-3/4
  3xl:w-1/2
  mx-auto
  relative
  pr-10
`;

// Nav links
const NavItems = tw.ul`
  list-none
  flex
  flex-wrap
  gap-x-8
  h-full
  justify-end
  items-center
`;

const NavItem = tw.li`
  flex
  justify-start
  lg:justify-center
  items-center
  cursor-pointer
  my-[20px]
  md:my-0
  text-primaryWhite
  text-base
  font-body
`;

const Button = tw.button`
  px-3
  py-1
  rounded-lg
  text-primaryBlack
  bg-primaryWhite
`;

const WalletButtonContainer = tw.div`
  rounded-lg
  bg-primaryWhite
  p-1
  flex
  flex-col
  md:flex-row
  justify-center
  items-center
  text-sm
`;

const WalletAmt = tw.p`
  text-primaryBlack
  px-2
  text-sm
`;

const WalletButton = tw(Button)`
  text-primaryBlack
  bg-primaryYellow
  overflow-hidden
`;

//Logo + Social media component
const LogoSpan = tw.div`
  bg-primaryBlack
  absolute 
  top-0
  left-0
  flex
  flex-col
  justify-center
  items-center
  p-2
`;

const Logo = tw.img`
  w-36
`;

const Icons = tw.div`
  flex
  w-full
  justify-between
  gap-2
  pt-2
`;

const Icon = tw.div`
  flex-1
  bg-primaryWhite
  flex
  justify-center
  items-center
  py-1
  md:py-2
`;

export function NavBar(props: NavbarInput) {
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const navigate = useNavigate();
  const [tabOpen, setTabOpen] = useState(false);
  const [walletAdd, setWalletAdd] = useState("");
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const asyncWrapper = async () => {
      const { address, status } = await getCurrentWalletConnected();
      const balance = await getCurrentWalletBalance(address);
      setWalletAdd(address);
      setBalance(balance);
      console.log(status);
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", async (accounts: any) => {
          if (accounts.length > 0) {
            let address = ethers.utils.getAddress(accounts[0]);
            const balance = await getCurrentWalletBalance(address);
            setWalletAdd(address);
            setBalance(balance);
          } else {
            setWalletAdd("");
            setBalance(0);
          }
        });
      } else {
        console.log("pls install metamask");
      }
    };
    asyncWrapper();
  }, []);

  //close hamburger menu on any link click
  function closeTab() {
    setTabOpen(false);
  }

  //toggle hamburger menu when bars are clicked
  function toggleTab() {
    setTabOpen((prev) => !prev);
  }

  const scrollToHome = async () => {
    closeTab();
    await navigate("/");
    await animateScroll.scrollToTop({
      smooth: "easeInOutQuint",
      duration: 1500,
    });
  };

  const scrollToRoadmap = async () => {
    closeTab();
    await navigate("/");
    await scroller.scrollTo("Roadmap", {
      smooth: "easeInOutQuint",
      duration: 1500,
    });
  };

  const scrollToLore = async () => {
    closeTab();
    await navigate("/");
    await scroller.scrollTo("Lore", {
      smooth: "easeInOutQuint",
      duration: 1500,
    });
  };

  const navItems = (
    <NavItems>
      <NavItem>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "font-bold text-" + (props.activeTextColor || "primaryYellow")
              : "text-" + (props.textColor || "primaryWhite")
          }
          to="/"
          onClick={scrollToHome}
        >
          Home
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          to="/"
          onClick={scrollToLore}
          className={"text-" + (props.textColor || "primaryWhite")}
        >
          Lore
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "font-bold text-" + (props.activeTextColor || "primaryYellow")
              : "text-" + (props.textColor || "primaryWhite")
          }
          to="/mint"
        >
          Mint
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          onClick={scrollToRoadmap}
          to="/"
          className={"text-" + (props.textColor || "primaryWhite")}
        >
          Roadmap
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "font-bold text-" + (props.activeTextColor || "primaryYellow")
              : "text-" + (props.textColor || "primaryWhite")
          }
          to="/team"
        >
          Team
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "font-bold text-" + (props.activeTextColor || "primaryYellow")
              : "text-" + (props.textColor || "primaryWhite")
          }
          to="/faq"
        >
          FAQ
        </NavLink>
      </NavItem>
      <NavItem>
        {walletAdd === "" ? (
          <Button onClick={connectWallet}>Connect Wallet</Button>
        ) : (
          <WalletButtonContainer>
            <WalletAmt>{balance.toFixed(3)}ETH</WalletAmt>
            <WalletButton>
              {walletAdd.substring(0, 3) +
                "..." +
                walletAdd.substring(walletAdd.length - 3)}
            </WalletButton>
          </WalletButtonContainer>
        )}
      </NavItem>
    </NavItems>
  );

  const hamburgerNavItems = (
    <NavItems>
      <NavItem>
        <NavLink
          className={({ isActive }) =>
            isActive ? "font-bold text-primaryYellow" : "text-primaryWhite"
          }
          to="/"
          onClick={scrollToHome}
        >
          Home
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/" onClick={scrollToLore} className="text-primaryWhite">
          Lore
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={({ isActive }) =>
            isActive ? "font-bold text-primaryYellow" : "text-primaryWhite"
          }
          to="/mint"
        >
          Mint
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink onClick={scrollToRoadmap} to="/" className="text-primaryWhite">
          Roadmap
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={({ isActive }) =>
            isActive ? "font-bold text-primaryYellow" : "text-primaryWhite"
          }
          to="/team"
        >
          Team
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={({ isActive }) =>
            isActive ? "font-bold text-primaryYellow" : "text-primaryWhite"
          }
          to="/faq"
        >
          FAQ
        </NavLink>
      </NavItem>
      <NavItem>
        {walletAdd === "" ? (
          <Button onClick={connectWallet}>Connect Wallet</Button>
        ) : (
          <WalletButtonContainer>
            <WalletAmt>{balance.toFixed(3)}ETH</WalletAmt>
            <WalletButton>
              {walletAdd.substring(0, 3) +
                "..." +
                walletAdd.substring(walletAdd.length - 3)}
            </WalletButton>
          </WalletButtonContainer>
        )}
      </NavItem>
    </NavItems>
  );

  // Hamburger menu styles
  const styles = {
    bmBurgerButton: {
      position: "absolute",
      width: "24px",
      height: "24px",
      right: "30px",
      top: "8px",
    },
    bmBurgerBars: {
      background: "#bdc3c7",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      marginTop: "20px",
      width: "50%",
      maxWidth: "300px",
    },
    bmMenu: {
      background: "var(--color-primary-black)",
      padding: "0.5em 1em 0",
      fontSize: "1.15em",
      height: "fit-content",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em",
    },
    bmItem: {
      display: "inline-block",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0)",
    },
  };

  const navItemsBurger = (
    <Menu
      right
      styles={styles}
      isOpen={tabOpen}
      onClose={toggleTab}
      onOpen={toggleTab}
      customCrossIcon={false}
    >
      {hamburgerNavItems}
    </Menu>
  );

  return (
    <Container
      className={
        props.gradient
          ? "bg-gradient-to-b from-primaryYellow to-transparent"
          : "bg-" + (props.barColor || "primaryBlack")
      }
    >
      <Bar
        className={
          !props.gradient ? "bg-" + (props.barColor || "primaryBlack") : ""
        }
      >
        {props.socials ? (
          <LogoSpan>
            <Logo
              src={props.blueLogo ? BlueCCCLogo : BlackCCCLogo}
              className="bg-primaryWhite cursor-pointer"
              onClick={scrollToHome}
            ></Logo>
            <IconContext.Provider
              value={{
                style: { fontSize: "30px", color: "var(--primary-black)" },
              }}
            >
              <Icons>
                <Icon style={{ backgroundColor: "gray" }}>
                  {/* <a href="https://www.youtube.com"> */}
                  <FiYoutube style={{ cursor: "not-allowed" }} />
                  {/* </a> */}
                </Icon>
                <Icon>
                  <a href="https://twitter.com/ccc_nft">
                    <FaTwitter />
                  </a>
                </Icon>
                <Icon style={{ backgroundColor: "gray" }}>
                  {/* <a href="https://www.notion.com"> */}
                  <SiNotion style={{ cursor: "not-allowed" }} />
                  {/* </a> */}
                </Icon>
              </Icons>
              <Icons>
                <Icon>
                  <a href="https://opensea.io/collection/cosmiccryptocrusade">
                  <img
                    src={OpenseaLogo}
                    width={28}
                  />
                  </a>
                </Icon>
                <Icon>
                  <a href="https://looksrare.org/collections/0xC9433cd80f6f5D4421Eb0b0c3D221A3a9Dff1B7b">
                  <img
                    src={Looksrare}
                    width={28}
                  />
                  </a>
                </Icon>
                <Icon>
                  <FaEllipsisH style={{ cursor: "not-allowed" }} />
                </Icon>
              </Icons>
            </IconContext.Provider>
          </LogoSpan>
        ) : (
          <div className="absolute left-0 top-0 cursor-pointer">
            <Logo
              src={props.blueLogo ? BlueCCCLogo : BlackCCCLogo}
              onClick={scrollToHome}
            ></Logo>
          </div>
        )}
        {isMobile && navItemsBurger}
        {!isMobile && navItems}
      </Bar>
    </Container>
  );
}
