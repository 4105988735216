import tw from "twin.macro";
import { NavBar } from "../NavBar";
import { BsArrowRightCircle } from "react-icons/bs";
import characters from "../../assets/characters.png";
import HomeData from "../Home/home-data.json";
import ethIcon from "../../assets/eth_icon.png";
import ProgressStatus from "../ProgressBar/ProgressStatus";
import MintModal from "./MintModal";
import { useEffect, useState } from "react";
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import TimerComp from "../Timer";
import HomeLogo from "../../assets/home_bg_logo.webp";
import StatusModal from "./StatusModal";
import { getCurrentWalletConnected, loadInfo } from "./interact";
import { BigNumber } from "ethers";

const Container = tw.div`
  bg-primaryYellow
  min-h-screen
  w-full
`;

const NavWrapper = tw.div`
  absolute
  top-0
  w-full
  z-10
`;

const Section = tw.div`
  mx-auto
  2xl:w-3/4
  3xl:w-1/2
  flex
  flex-col
  md:flex-row
  bg-primaryYellow
`;

const SectionLeft = tw.div`
  flex
  flex-row
  justify-start
  items-start
  md:w-1/2
  md:sticky
  md:top-0
  md:h-full
`;

const SectionRight = tw.div`
  text-primaryWhite
  w-full
  flex
  flex-col
  md:w-1/2
  gap-4
  pt-16
  px-6
`;

const Title = tw.h1`
  text-3xl
  text-primaryYellow
  font-header
  py-6
`;

const MintSoon = tw(Title)`
  text-primaryBlack
  text-center
`;

const InfoTitle = tw(Title)`
  text-primaryGreen
`;

const CTA = tw.div`
  text-right
  font-body
  text-primaryBlack
  flex
  justify-end
  items-center
  p-5
  bg-primaryYellow
  cursor-pointer
  md:sticky
  md:bottom-0
`;

const CTAText = tw.h3`
  mr-2
`;

const CarouselContainer = tw.div`
  overflow-y-hidden
  h-screen
  w-full
`;

const CarouselImage = tw.img`
  animate-[marquee]
`;

const Subtitle = tw(Title)`
  text-2xl
`;

const Grid = tw.div`
  mt-4
  w-full
  bg-primaryWhite
  rounded-lg
  p-4
  pt-0
  text-center
  gap-4
`;

const Para = tw.p`
  text-base
  text-primaryBlack
  font-body
  whitespace-pre-line
`;

const EthSubsection = tw.div`
  my-1
`;

const HeroesSubsection = tw.div`
  font-body
  text-primaryBlack
  my-1
`;

const HeroesMinted = tw.div`
  text-5xl
  font-header
  text-primaryBlack
`;

const EthMinted = tw.div`
  text-5xl
  font-header
  text-primaryBlack
  flex
  flex-row
  items-center
`;

const MintButtons = tw.button`
  bg-[rgba(255, 255, 255, 0.4)]
  hover:bg-primaryGreen
  text-primaryGreen
  hover:text-primaryWhite
  p-2
  px-6
  w-max
  rounded-3xl
  text-xl
  font-header
  cursor-pointer
`;

const Logo = tw.img`
  w-2/3
  md:w-1/3
`;

export function Mint() {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const [info, setInfo] = useState({
    mintPrice: 0.2,
    maxMintPerTx: 100,
    cccTotal: 5000,
    cccCount: 0,
    vipUserTotal: 0,
    vipUserCount: 0,
    cccUserCount: 0,
    mint: BigNumber.from("2000000000000000000"),
    discount: BigNumber.from("500000000000000000"),
    totalCCC: 0,
    totalCCCbyTeam: 0,
    totalETH: 0,
  });
  const targetTime = 1665411010000; // 2022-10-10 22:10:10 GMT+8
  // const targetTime = 1664258532000; // for testing
  const awaitingMint = Date.now() < targetTime;

  function scrollToTop() {
    animateScroll.scrollToTop({
      smooth: "easeInOutQuint",
      duration: 1000,
    });
  }

  function mintClick() {
    scrollToTop();
    setModal(true);
    removeScroll();
  }

  function removeScroll() {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  }

  function restoreScroll() {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
  }

  function modalCloseHandler() {
    setStatus("");
    restoreScroll();
    setModal(false);
  }

  useEffect(() => {
    const asyncWrapper = async () => {
      const { address, status } = await getCurrentWalletConnected();
      const info_ = await loadInfo(address);
      console.log(info_);
      setInfo(info_);
    };
    asyncWrapper();
  }, []);

  return (
    <Container>
      {modal && (
        <MintModal
          closeHandler={modalCloseHandler}
          updateMsg={setError}
          updateStatus={setStatus}
        />
      )}
      {status !== "" && (
        <StatusModal
          status={status}
          message={error}
          closeHandler={modalCloseHandler}
        />
      )}
      <NavWrapper>
        <NavBar
          blueLogo
          gradient
          textColor="primaryBlack"
          activeTextColor="primaryWhite"
        />
      </NavWrapper>
      {awaitingMint ? (
        <Section className="justify-center align-center m-0">
          <CarouselContainer style={{ width: "auto" }}>
            <CarouselImage
              src={characters}
              style={{
                animation: "marquee 50s linear infinite reverse",
              }}
            />
          </CarouselContainer>
          <div className="h-screen px-20 flex flex-col justify-center items-center">
            <Logo src={HomeLogo} />
            <MintSoon>Minting soon!</MintSoon>
            <TimerComp epoch={targetTime} />
          </div>
          <CarouselContainer style={{ width: "auto" }}>
            <CarouselImage
              src={characters}
              style={{
                animation: "marquee 50s infinite linear",
              }}
            />
          </CarouselContainer>
        </Section>
      ) : (
        <Section>
          <SectionLeft>
            <CarouselContainer>
              <CarouselImage
                src={characters}
                style={{
                  animation: "marquee 50s linear infinite reverse",
                }}
              />
            </CarouselContainer>
            <CarouselContainer>
              <CarouselImage
                src={characters}
                style={{
                  animation: "marquee 50s infinite linear",
                }}
              />
            </CarouselContainer>
          </SectionLeft>
          <SectionRight
          // className="max-h-screen overflow-y-auto"
          // style={{ scrollSnapType: "y mandatory" }}
          >
            {/* <span className="pt-10" style={{ scrollSnapAlign: "start" }}> */}
            <InfoTitle>Make an impact</InfoTitle>
            <EthSubsection>
              <EthMinted>
                <img src={ethIcon} alt="" />
                {info.totalETH}
              </EthMinted>
              <ProgressStatus current={info.totalETH} />
            </EthSubsection>
            <HeroesSubsection>
              <HeroesMinted>{info.totalCCC - info.totalCCCbyTeam}</HeroesMinted>
              <p>Heroes minted</p>
            </HeroesSubsection>
            <MintButtons onClick={() => mintClick()}>Mint Now</MintButtons>
            <Para>
              Mint @ {info.mintPrice} ETH, Whitelist @{" "}
              {Math.round((info.mintPrice - 0.05) * 100) / 100} ETH
            </Para>
            <Grid>
              <Subtitle>{HomeData.milestone_1_title}</Subtitle>
              <Para>{HomeData.milestone_1_content}</Para>
            </Grid>
            <Grid>
              <Subtitle>{HomeData.milestone_2_title}</Subtitle>
              <Para>{HomeData.milestone_2_content}</Para>
            </Grid>
            <Grid>
              <Subtitle>{HomeData.milestone_3_title}</Subtitle>
              <Para>{HomeData.milestone_3_content}</Para>
            </Grid>
            <CTA>
              <CTAText>
                <Link to="/faq" onClick={scrollToTop}>
                  Still have questions? Visit our FAQ
                </Link>
              </CTAText>
              <BsArrowRightCircle />
            </CTA>
          </SectionRight>
        </Section>
      )}
    </Container>
  );
}

// import { useEffect, useState } from "react";
// import styled from 'styled-components';
// import tw from "twin.macro";
// import {Plus, Minus} from '@styled-icons/boxicons-regular'
// import { ethers } from "ethers";
// import {
//   cccStoreContract,
//   connectWallet,
//   getCurrentWalletConnected,
//   loadTimeline,
//   loadInfo,
//   mintWithPass,
//   mintCCC,
//   passSig,
// } from "./interact";
// import { NavBar } from "../NavBar";
// import MintPic0 from "../../assets/mint_pic_0.gif"
// import TimerComp from "../Timer";

// const Container = tw.div`
//   flex
//   flex-col
// 	bg-black
//   font-sans
//   gap-6
//   p-6
//   min-h-screen
// `;

// const ContainerLoading = tw(Container)`
// `;

// const Title = tw.p`
//   text-4xl
//   font-bold
//   text-primaryYellow
//   text-center
//   p-6
// `;

// const Para = tw.p`
//   text-lg
//   text-white
//   text-center
// `;

// const Pic = tw.img`
//   h-52
//   w-52
//   xs:h-72
//   xs:w-72
//   border
//   rounded-lg
//   border-solid
//   border-white
//   mx-auto
//   mt-6
// `;

// const Section = tw.div`
//   flex
//   flex-col
//   lg:flex-row
//   justify-center
//   gap-36
//   p-6
// `;

// const Item = tw.div`
//   flex
//   flex-col
//   justify-center
//   gap-6
// `;

// const Dropdown = tw.select`
//   h-12
//   w-60
//   m-auto
// `;

// const Option = tw.option`
//   text-center
// `;

// const Button = tw.p`
//   bg-primaryYellow
//   text-black
//   font-medium
//   h-12
//   w-60
//   m-auto
//   flex
//   items-center
//   justify-center
//   cursor-pointer
// `;

// const ButtonInactive = tw(Button)`
//   cursor-not-allowed
//   grayscale
// `;

// const MintCounter = tw.div`
//   flex
//   items-center
//   m-auto
// `;

// const PlusWrapped = styled(Plus)`
//   color: white;
//   height: 24px;
//   width: 20px;
// `;

// const MinusWrapped = styled(Minus)`
//   color: white;
//   height: 24px;
//   width: 20px;
// `;

// const MintCounterAmount = styled.span`
//   color: white;
//   width: 60px;
//   padding-right: 10px;
//   text-align: right;
// `;

// export function Mint() {
//   // state variables
//   const [timeline, setTimeline] = useState(0);
//   const [targetDate, setTargetDate] = useState(-1);

//   // called only once
//   useEffect(() => {
//     const asyncWrapper = async () => {
//       // timeline 0 - before presale
//       // timeline 1 - ongoing presale
//       // timeline 2 - ongoing public sale
//       // timeline 3 - after public sale
//       const info = await loadTimeline();
//       const currDate = Date.now();
//       // let timeline = 0;
//       // let targetDate = 0;
//       if (currDate < info.presaleStartDate) {
//         setTimeline(0);
//         setTargetDate(info.presaleStartDate);
//       } else if (currDate < info.presaleEndDate) {
//         setTimeline(1);
//         setTargetDate(info.presaleEndDate);
//       } else if (currDate < info.publicSaleEndDate) {
//         setTimeline(2);
//         setTargetDate(info.publicSaleEndDate);
//       } else {
//         setTimeline(3);
//         setTargetDate(0); // TODO: change default to -1, and handle text for after public sale
//       };
//     };
//     asyncWrapper();
//   }, []);

//   const title = (timeline < 2) ? "CCC NFT PRESALE" : "CCC NFT PUBLIC SALE";
//   const timerText = (timeline < 1) ? "Time to presale" : "Time remaining";

//   // state variables
//   const [walletAddress, setWallet] = useState("");
//   const [status, setStatus] = useState("");
//   const [ticketPrice, setTicketPrice] = useState(0);
//   const [maxMintPerTx, setMaxMintPerTx] = useState(0);
//   const [nftOwnerTotal, setNftOwnerTotal] = useState(0);
//   const [discordTotal, setDiscordTotal] = useState(0);
//   const [cccTotal, setCCCTotal] = useState(0);
//   const [nftOwnerCount, setNftOwnerCount] = useState(0);
//   const [discordCount, setDiscordCount] = useState(0);
//   const [cccCount, setCCCCount] = useState(0);
//   const [nftOwnerUserCount, setNftOwnerUserCount] = useState(0);
//   const [discordUserCount, setDiscordUserCount] = useState(0);
//   const [cccUserCount, setCCCUserCount] = useState(0);
//   const [nftOwnerUserTotal, setNftOwnerUserTotal] = useState(0);
//   const [discordUserTotal, setDiscordUserTotal] = useState(0);
//   const [nftOwnerMintAmount, setNftOwnerMintAmount] = useState(0);
//   const [discordMintAmount, setDiscordMintAmount] = useState(0);
//   const [publicMintAmount, setPublicMintAmount] = useState(0);

//   // called only once
//   useEffect(() => {
//     const asyncWrapper = async () => {
//       const { address, status } = await getCurrentWalletConnected();
//       setWallet(address);
//       setStatus(status);
//       const info = await loadInfo(address);
//       setTicketPrice(info.ticketPrice);
//       setMaxMintPerTx(info.maxMintPerTx);
//       setNftOwnerTotal(info.nftOwnerTotal);
//       setDiscordTotal(info.discordTotal);
//       setCCCTotal(info.cccTotal);
//       setNftOwnerCount(info.nftOwnerCount);
//       setDiscordCount(info.discordCount);
//       setCCCCount(info.cccCount);
//       setNftOwnerUserCount(info.nftOwnerUserCount);
//       setDiscordUserCount(info.discordUserCount);
//       setCCCUserCount(info.cccUserCount);
//       const addressType0 = address + ",0";
//       const addressType1 = address + ",1";
//       if (addressType0 in passSig) {
//         setNftOwnerUserTotal(passSig[addressType0].amount);
//       };
//       if (addressType1 in passSig) {
//         setDiscordUserTotal(passSig[addressType1].amount);
//       };

//       addWalletListener();
//       addSmartContractListener();
//     };
//     asyncWrapper();
//   }, []);

//   function addWalletListener() {
//     if (window.ethereum) {
//       window.ethereum.on("accountsChanged", async (accounts: any) => {
//         if (accounts.length > 0) {
//           let address = ethers.utils.getAddress(accounts[0]);
//           setWallet(address);

//           setStatus("Ready to mint!");
//           await onChangeAddress(address);
//         } else {
//           setWallet("");
//           setStatus("🦊 Connect to Metamask.");
//           await onChangeAddress("");
//         }
//       });
//     } else {
//       setStatus("Please install Metamask.");
//     }
//   }

//   function addSmartContractListener() {
//     cccStoreContract.on("MintWithPass", async (account, mintRequestAmount, changes, event) => {
//       const walletResponse = await connectWallet();
//       const cccMinted = mintRequestAmount.toNumber();
//       const info = await loadInfo(walletResponse.address);
//       setNftOwnerCount(info.nftOwnerCount);
//       setDiscordCount(info.discordCount);
//       if (account === walletResponse.address) {
//         setStatus(`Congratulations! You just minted ${cccMinted} CCCs!`);
//         setNftOwnerUserCount(info.nftOwnerUserCount);
//         setDiscordUserCount(info.discordUserCount);
//       }
//     });
//     cccStoreContract.on("MintCCC", async (account, mintRequestAmount, changes, event) => {
//       const walletResponse = await connectWallet();
//       const cccMinted = mintRequestAmount.toNumber();
//       const info = await loadInfo(walletResponse.address);
//       setCCCCount(info.cccCount);
//       if (account === walletResponse.address) {
//         setStatus(`Congratulations! You just minted ${cccMinted} CCCs!`);
//         setCCCUserCount(info.cccUserCount);
//       }
//     });
//   }

//   const connectWalletPressed = async () => {
//     const walletResponse = await connectWallet();
//     setWallet(walletResponse.address);
//     setStatus(walletResponse.status);
//     await onChangeAddress(walletResponse.address);
//   };

//   const onChangeAddress = async (address: string) => {
//     const info = await loadInfo(address);
//     setNftOwnerUserCount(info.nftOwnerUserCount);
//     setDiscordUserCount(info.discordUserCount);
//     setCCCUserCount(info.cccUserCount);
//     setNftOwnerMintAmount(0);
//     setDiscordMintAmount(0);
//     setPublicMintAmount(0);
//     const addressType0 = `${address},0`;
//     const addressType1 = `${address},1`;
//     if (addressType0 in passSig) {
//       setNftOwnerUserTotal(passSig[addressType0].amount);
//     } else {
//       setNftOwnerUserTotal(0);
//     };
//     if (addressType1 in passSig) {
//       setDiscordUserTotal(passSig[addressType1].amount);
//     } else {
//       setDiscordUserTotal(0);
//     };
//   }

//   const nftOwnerMintCCC = async (amount: number) => {
//     if (amount > 0) {
//       await mintWithPass(amount, 0);
//       setStatus("Minting CCC...");
//     }
//     else {
//       setStatus("Please select amount to mint.");
//     };
//   };

//   const discordMintCCC = async (amount: number) => {
//     if (amount > 0) {
//       await mintWithPass(amount, 1);
//       setStatus("Minting CCC...");
//     }
//     else {
//       setStatus("Please select amount to mint.");
//     };
//   };

//   const publicMintCCC = async (amount: number) => {
//     if (amount > 0) {
//       await mintCCC(amount);
//       setStatus("Minting CCC...");
//     }
//     else {
//       setStatus("Please select amount to mint.");
//     };
//   };

//   let connectWalletText = "Connect Wallet";
//   if (walletAddress.length > 0) {
//     connectWalletText = "Connected: " +
//      String(walletAddress).substring(0, 6) +
//      "..." +
//      String(walletAddress).substring(38);
//   };
//   const options = (remaining: number) => {
//     var options = [];
//     for (let i = 0; i < Math.min(remaining, maxMintPerTx) + 1; i++) {
//       options.push(<Option value={i} key={i}>{i}</Option>);
//     };
//     return options;
//   };

//   const nftOwnerItem = (
//     <Item>
//       <Title>VIP</Title>
//       <MintCounter>
//         <button onClick={() => setNftOwnerMintAmount(nftOwnerMintAmount>=maxMintPerTx ? maxMintPerTx : nftOwnerMintAmount+1)}><PlusWrapped></PlusWrapped></button>
//         <MintCounterAmount>{nftOwnerMintAmount}/{maxMintPerTx}</MintCounterAmount>
//         <button onClick={() => setNftOwnerMintAmount(nftOwnerMintAmount>0 ? nftOwnerMintAmount-1 : 0)}><MinusWrapped></MinusWrapped></button>
//       </MintCounter>
//       {nftOwnerMintAmount ?
//       <Button onClick={() => nftOwnerMintCCC(nftOwnerMintAmount)}>MINT NOW</Button> :
//       <ButtonInactive>MINT NOW</ButtonInactive>}
//       <Para>You have minted: {nftOwnerUserCount}/{nftOwnerUserTotal}</Para>
//       <Para>{nftOwnerCount}/{nftOwnerTotal} minted</Para>
//     </Item>
//   )

//   const discordItem = (
//     <Item>
//       <Title>DISCORD</Title>
//       <MintCounter>
//         <button onClick={() => setDiscordMintAmount(discordMintAmount>=maxMintPerTx ? maxMintPerTx : discordMintAmount+1)}><PlusWrapped></PlusWrapped></button>
//         <MintCounterAmount>{discordMintAmount}/{maxMintPerTx}</MintCounterAmount>
//         <button onClick={() => setDiscordMintAmount(discordMintAmount>0 ? discordMintAmount-1 : 0)}><MinusWrapped></MinusWrapped></button>
//       </MintCounter>
//       {discordMintAmount ?
//       <Button onClick={() => discordMintCCC(discordMintAmount)}>MINT NOW</Button> :
//       <ButtonInactive>MINT NOW</ButtonInactive>}
//       <Para>You have minted: {discordUserCount}/{discordUserTotal}</Para>
//       <Para>{discordCount}/{discordTotal} minted</Para>
//     </Item>
//   )

//   const publicItem = (
//     <Item>
//       <Title>PUBLIC SALE</Title>
//       <MintCounter>
//         <button onClick={() => setPublicMintAmount(publicMintAmount>=maxMintPerTx ? maxMintPerTx : publicMintAmount+1)}><PlusWrapped></PlusWrapped></button>
//         <MintCounterAmount>{publicMintAmount}/{maxMintPerTx}</MintCounterAmount>
//         <button onClick={() => setPublicMintAmount(publicMintAmount>0 ? publicMintAmount-1 : 0)}><MinusWrapped></MinusWrapped></button>
//       </MintCounter>
//       {publicMintAmount ?
//       <Button onClick={() => publicMintCCC(publicMintAmount)}>MINT NOW</Button> :
//       <ButtonInactive>MINT NOW</ButtonInactive>}
//       <Para>You have minted: {cccUserCount}</Para>
//       <Para>{cccCount}/{cccTotal} minted</Para>
//     </Item>
//   )

//   return (
//     <div>
//     <NavBar />
//     {targetDate > -1 ?
//     <Container>
//       <Title>{title}</Title>
//       <Para>Join the Cosmic Crypto Crusade - Take a side and play your part in keeping the crypto space safe.</Para>
//       <Para>{timerText}</Para>
//       <TimerComp epoch={targetDate}/>
//       <Pic src={MintPic0}></Pic>
//       <br></br>
//       <Para>{status}</Para>
//       {walletAddress.length<1 &&
//       <Button onClick={connectWalletPressed}>{connectWalletText}</Button>}
//       {walletAddress.length>1 &&
//       <Section>
//       {(timeline < 2) && nftOwnerItem}
//       {(timeline < 2) && discordItem}
//       {(timeline > 1) && publicItem}
//       </Section>}
//     </Container> :
//     <ContainerLoading>
//       <Para>Loading...</Para>
//     </ContainerLoading>}
//     </div>
//   );
// }
