import { useState } from "react";
import tw from "twin.macro";

interface FAQdata {
  question: string;
  answer: string;
  image?: string;
}

const Container = tw.div`
    px-6
    py-4
    font-body
`;

const Accordion = tw.button`
    cursor-pointer
    w-full
    text-left
    border-none
    outline-none
    duration-300
    flex
    flex-row
    justify-between
    font-body_bold
    text-lg
`;

const Panel = tw.div`
    text-base
    whitespace-pre-line
    transition-all
    overflow-hidden
`;

export function FAQcollapsible(props: FAQdata) {
  const { question, answer, image } = props;
  const [open, setOpen] = useState(false);
  return (
    <Container>
      <Accordion onClick={() => setOpen((prev) => !prev)}>
        <div>{question}</div>
        <div>{open ? "-" : "+"}</div>
      </Accordion>
      <Panel className={open ? "max-h-[1000px] mt-6 mb-4" : "h-0 m-0"}>
        {answer}
        {image !== "" && <img src={require("../../assets/" + image)} />}
      </Panel>
    </Container>
  );
}
