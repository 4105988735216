import { ethers, BigNumber } from "ethers";
import cccFactory from "./CCCFactory.json";
import cccPass from "./CCCPass.json";
import cccStore from "./CCCStore.json";
import passSignatures from "./pass-signatures.json";

interface PassData {
  rSig: string;
  sSig: string;
  vSig: number;
  amount: number;
}

interface PassSig {
  [addressType: string]: PassData;
}

interface info_ {
  mintPrice: number;
  maxMintPerTx: number;
  cccTotal: number;
  cccCount: number;
  vipUserTotal: number;
  vipUserCount: number;
  cccUserCount: number;
  mint: BigNumber;
  discount: BigNumber;
  totalCCC: number;
  totalCCCbyTeam: number;
  totalETH: number;
}

let provider: ethers.providers.JsonRpcProvider;
// const alchemyKey =
//   "wss://eth-goerli.g.alchemy.com/v2/94BLlnrXSyWtWiEy31D7MKZeCjZlgoM_";
// export const provider = new ethers.providers.WebSocketProvider(
//   alchemyKey,
//   "goerli"
// );

if (window.ethereum) {
  provider = new ethers.providers.Web3Provider(window.ethereum);
} else {
  provider = new ethers.providers.JsonRpcProvider("https://mainnet.infura.io/v3/adcbb810cc1f47f9b47703b5bdd119ac");
}

export const passSig: PassSig = passSignatures || "";

export const cccFactoryContract = new ethers.Contract(
  "0xC9433cd80f6f5D4421Eb0b0c3D221A3a9Dff1B7b",
  cccFactory.abi,
  provider
);

export const cccPassContract = new ethers.Contract(
  "0x036BfB0A16719b5a9812F6cbA3e974A52c57CF22",
  cccPass.abi,
  provider
);

export const cccStoreContract = new ethers.Contract(
  "0x8CE51278a22EDD6F7A99BAD1dF2b50868E5bb543",
  cccStore.abi,
  provider
);
//update opening hrs
export const loadTimeline = async () => {
  const promises = [cccStoreContract.openingHours()];
  let res = await Promise.all(promises);
  res = res.map((x) => x.toNumber() * 1000);

  const info = {
    presaleStartDate: res[0],
  };
  return info;
};

//upadte load info
export const loadInfo = async (address: string) => {
  const promises = [
    cccStoreContract.mintPrice(),
    cccStoreContract.maxMintPerTx(),
    cccFactoryContract.MAX_SUPPLY(),
    cccFactoryContract.totalSupply(),
    address ? cccPassContract.claimedCount(address) : ethers.BigNumber.from(0),
    address ? cccFactoryContract.balanceOf(address) : ethers.BigNumber.from(0),
    cccStoreContract.VIPDiscount(),
    cccStoreContract.totalCCCMinted(),
    cccStoreContract.totalCCCMintedByTeam(),
    cccStoreContract.totalETHDonated(),
  ];
  const res = await Promise.all(promises);
  if (passSig.hasOwnProperty(address)) {
    var info: info_ = {
      mintPrice: res[0] / 10 ** 18,
      maxMintPerTx: res[1].toNumber(),
      cccTotal: res[2].toNumber(),
      cccCount: res[3].toNumber(),
      vipUserTotal: passSig[address].amount, //how much pass the user has
      vipUserCount: res[4].toNumber(), //how much pass the user has claimed
      cccUserCount: res[5].toNumber(), //how much CCC the user has minted
      mint: BigNumber.from(res[0]),
      discount: BigNumber.from(res[6]),
      totalCCC: res[7].toNumber(),
      totalCCCbyTeam: res[8].toNumber(),
      totalETH: res[9] / 10 ** 18,
    };
  } else {
    info = {
      mintPrice: res[0] / 10 ** 18,
      maxMintPerTx: res[1].toNumber(),
      cccTotal: res[2].toNumber(),
      cccCount: res[3].toNumber(),
      vipUserTotal: 0, //how much pass the user has
      vipUserCount: res[4].toNumber(), //how much pass the user has claimed
      cccUserCount: res[5].toNumber(), //how much CCC the user has minted
      mint: BigNumber.from(res[0]),
      discount: BigNumber.from(res[6]),
      totalCCC: res[7].toNumber(), //CCC minted by public is totalCCC - totalCCCbyTeam
      totalCCCbyTeam: res[8].toNumber(),
      totalETH: res[9] / 10 ** 18,
    };
  }
  return info;
};

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const addr = await ethers.utils.getAddress(addressArray[0]);
      const obj = {
        address: addr,
        status: "Ready to mint!",
      };
      return obj;
    } catch (err: any) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    window.alert("Please install Metamask.")
    return {
      address: "",
      status: "Please install Metamask.",
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        const addr = ethers.utils.getAddress(addressArray[0]);
        return {
          address: addr,
          status: "Ready to mint!",
        };
      } else {
        return {
          address: "",
          status: "🦊 Please connect to Metamask",
        };
      }
    } catch (err: any) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: "Please install Metamask.",
    };
  }
};
//add price calc
export const calculate_price = async (info: info_, amount: number) => {
  if (info.vipUserTotal === 0 || info.vipUserTotal - info.vipUserCount === 0) {
    //no pass or all passes used
    //no discount
    return info.mint.mul(amount);
  } else {
    //apply discount
    let discounted_amount = info.vipUserTotal - info.vipUserCount;
    if (discounted_amount >= amount) {
      //more pass than amount minting, so all has discount
      return info.mint.sub(info.discount).mul(amount);
    }
    return info.mint
      .sub(info.discount)
      .mul(discounted_amount)
      .add(info.mint.mul(amount - discounted_amount)); //less pass than amount minting, so only some has discount
  }
};

//collapse into one
export const mintCCC = async (info: info_, amount: number, address: string) => {
  if (!window.ethereum || amount === 0) {
    return;
  }
  const userProvider = new ethers.providers.Web3Provider(window.ethereum);
  const userSigner = userProvider.getSigner();
  const price = await calculate_price(info, amount);
  try {
    if (passSig.hasOwnProperty(address)) {
      return await cccStoreContract
        .connect(userSigner)
        .mintCCC(
          info.vipUserTotal,
          amount,
          passSig[address].vSig,
          passSig[address].rSig,
          passSig[address].sSig,
          { value: price.toString() }
        );
    } else {
      return await cccStoreContract
        .connect(userSigner)
        .mintCCC(
          info.vipUserTotal,
          amount,
          0,
          "0x0000000000000000000000000000000000000000000000000000000000000000",
          "0x0000000000000000000000000000000000000000000000000000000000000000",
          { value: price.toString() }
        );
    }
  } catch (error) {
    return ["Error", error];
  }
};

export const waiting_for_txn = async (txn: any) => {
  try {
    const timeout = new Promise((resolve, reject) => {
      setTimeout(resolve, 30000, "timeout"); //30s extend as needed
    });
    const receipt = provider.waitForTransaction(txn.hash);
    return await Promise.race([timeout, receipt]).then((value: any) => {
      console.log(receipt);
      console.log(value);
      if (value === "timeout") {
        return value;
      } else {
        console.log(value);
        if (value.status === 1) {
          return value.transactionHash;
        } else {
          return false;
        }
      }
    });
  } catch (error) {
    console.log("2 " + error);
    return error;
  }
};

export async function getCurrentWalletBalance(addr: string) {
  if (!addr) return 0;
  const hex_balance = await provider.getBalance(addr);
  const balance = parseFloat(ethers.utils.formatEther(hex_balance));
  return balance;
}
