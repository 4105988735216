import tw from "twin.macro";
import HomeData from "../Home/home-data.json";

interface ProgressStatusProps {
  current: number;
}

const Container = tw.div`
    w-full
    mx-auto
    p-2
`;

const BarBase = tw.div`
    bg-primaryWhite    
    h-[30px]
    rounded-full
    w-full
`;

const FilledBar = tw.div`
    h-[30px]
    rounded-full
`;

const Info = tw.div`
    font-body
    text-primaryBlack
    flex
    flex-row
    pt-4
`;

const Value = tw.div`
    font-header
    mr-2
`;

function ProgressStatus(props: ProgressStatusProps) {
  const milestones = [
    HomeData.milestone_1_value,
    HomeData.milestone_2_value,
    HomeData.milestone_3_value,
  ];
  var total = props.current;
  var nextMilestone = 3;
  for (const val in milestones) {
    if (props.current < milestones[val]) {
      total = milestones[val];
      nextMilestone = +val + 1;
      break;
    }
  }
  let percentage = (props.current / total) * 100;
  percentage = Math.max(percentage, 4); //min 4%
  percentage = Math.min(percentage, 100); //max 100%
  const difference = total - props.current;

  return (
    <Container>
      <BarBase>
        <FilledBar
          style={{
            width: percentage + "%",
            background: "var(--color-rainbow)",
          }}
        />
      </BarBase>
      <Info>
        <Value>{difference} ETH</Value> To Milestone {nextMilestone} goal
      </Info>
    </Container>
  );
}

export default ProgressStatus;
