import { useEffect, useState } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import tw from "twin.macro";
import mintData from "./mint-data.json";
import {
  loadInfo,
  calculate_price,
  mintCCC,
  waiting_for_txn,
  getCurrentWalletConnected,
} from "./interact";
import { BigNumber } from "ethers";
import { useMediaQuery } from "react-responsive";

interface MintModalProps {
  closeHandler: () => void;
  updateStatus: (status: string) => void;
  updateMsg: (msg: string) => void;
}

interface MintCharProps {
  title: String;
  value: number;
  description: String;
  setTotalAmt: Function;
  selected: boolean;
}

const Translucent = tw.div`
  fixed
  overflow-x-hidden
  bg-gray-600
  bg-opacity-50
  backdrop-blur-sm
  h-screen
  w-screen  
  z-20
`;

const Container = tw.div`
  mx-auto
  2xl:w-3/4
  3xl:w-1/2
  flex 
  justify-end
  h-full
  relative
  select-none
`;

const ModalContainer = tw.div`
  w-full
  md:w-2/5
  bg-primaryWhite
  animate-[slideIn]
  z-20
  md:absolute
  fixed
  right-0
  overflow-y-auto
  h-screen
`;

const TitleBar = tw.div`
  bg-primaryYellow
  p-6
`;

const Title = tw.h1`
  font-header
  text-2xl
`;

const Body = tw.div`
  p-6
`;

const Para = tw.p`
  font-body
  text-sm
  whitespace-pre-line
`;

const MintCharContainer = tw.div`
  gap-2
  flex
  flex-col
`;

const Character = tw.div`
  rounded-lg
  p-4
  flex
  flex-row
  justify-between
  items-center
  cursor-pointer
  hover:bg-primaryGreen
`;

const CharacterTitle = tw(Title)`
  text-xl
  mb-0
`;

const CustomMintContainer = tw.div`
  mt-4
`;

const CustomMintInputContainer = tw.div`
  w-full
  flex
  flex-row
  justify-center
  items-center
`;

const CustomMintInput = tw.input`
  m-4
  w-3/4
  p-2
  rounded-lg
`;

const ButtonContainer = tw.div`
  flex
  flex-row
  gap-4
  justify-center
  items-center
`;

const Button = tw.button`
  bg-primaryWhite
  border-2
  border-primaryGreen
  text-primaryGreen
  py-1
  w-1/2
  font-header
  rounded-lg
`;

const ButtonColored = tw(Button)`
  bg-primaryGreen
  text-primaryWhite
`;

const TotalEth = tw(Para)`
  rounded-lg
  p-2
`;

const OrderSummary = tw.div`
  font-body
  flex
  flex-col
  my-4
  py-4
  border-t
  border-black
  gap-4
  text-primaryGreen
`;
const OrderSection = tw.div`
  flex
  justify-between
`;

const OrderStat = tw.div`
  flex
  flex-col
  text-right
`;

const MobileUnsupportedMsg = tw(Para)`
  p-4
  text-center
`;

const MintChar = (props: MintCharProps) => {
  function clickHandler() {
    props.setTotalAmt();
  }
  return (
    <Character
      className={props.selected ? "bg-primaryGreen" : "bg-[#42707766]"}
      onClick={clickHandler}
    >
      <div>
        <CharacterTitle>{props.title}</CharacterTitle>
        <Para>Total: {props.value} ETH</Para>
        <Para>{props.description}</Para>
      </div>
    </Character>
  );
};

function MintModal(props: MintModalProps) {
  const MINNFTS = mintData["MINNFTS"];
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const [whitelisted, setWhitelisted] = useState(false);
  const [numNfts, setNumNfts] = useState(0);
  const [minting, setMinting] = useState(false);
  const [passLeft, setPass] = useState(0);
  const [info, setInfo] = useState({
    mintPrice: 0.2,
    maxMintPerTx: 100,
    cccTotal: 5000,
    cccCount: 0,
    vipUserTotal: 0,
    vipUserCount: 0,
    cccUserCount: 0,
    mint: BigNumber.from("2000000000000000000"),
    discount: BigNumber.from("500000000000000000"),
    totalCCC: 0,
    totalCCCbyTeam: 0,
    totalETH: 0,
  });
  const [address, setAddress] = useState("");

  useEffect(() => {
    const asyncWrapper = async () => {
      const { address, status } = await getCurrentWalletConnected();
      console.log(address);
      const info_ = await loadInfo(address);
      console.log(info_);
      setInfo(info_);
      setPass(info_.vipUserTotal - info_.vipUserCount);
      setAddress(address);
      if (info_.vipUserTotal !== 0) setWhitelisted(true);
      // console.log(info_.mintPrice) mintprice is 0.1 on testnet
    };
    asyncWrapper();
  }, []);

  function plusHandler() {
    setNumNfts(Math.min(info.maxMintPerTx, numNfts + 1));
  }

  function minusHandler() {
    setNumNfts(Math.max(MINNFTS, numNfts - 1));
  }

  function roundToDp(num: number) {
    return Math.round(num * 100) / 100;
  }

  function getTotalCost(numNfts: number) {
    return numNfts * info.mintPrice;
  }

  function getDiscount(numNfts: number) {
    if (numNfts > passLeft) {
      return [passLeft * 0.05, passLeft];
    } else {
      return [numNfts * 0.05, numNfts];
    }
  }

  async function submitMint() {
    if (address === "") {
      props.updateStatus("fail");
      props.updateMsg("Please connect metamask wallet.");
      return;
    }
    if (numNfts === 0) {
      return;
    }
    await setMinting(true);
    const totalPrice = getTotalCost(numNfts) - getDiscount(numNfts)[0]; //the decimals in this total cost is not preserved. I think
    // if (
    //   window.confirm(
    //     `Are you sure you want to mint ${numNfts} NFTs and pay a total price of ${totalPrice}ETH?`
    //   )
    // ) {
    var price = await calculate_price(info, numNfts); //price in testnet is 0.1
    console.log(price, totalPrice); //doublecheck price calculation is the same but take into account above^
    // window.alert("waiting for confirmation");
    const txn = await mintCCC(info, numNfts, address);
    if (txn[0] === "Error") {
      console.log(txn[1].message);

      // window.alert(`${txn[1].message}`); //catches mm eror, very ugly error when nonce error though
      props.updateStatus("fail");
      if (txn[1].message.includes("insufficient funds")) {
        console.log("not enough money");
        // if there is not enough eth
        props.updateMsg("Please make sure you have enough ETH in your wallet!");
        return;
      }
      if (txn[1].message.includes("User denied")) {
        // if there is not enough eth
        props.updateMsg("Transaction denied by user, please try again!");
        return;
      } else {
        // unknown error
        props.updateMsg("Something went wrong! Please try again");
        return;
      }
    } else {
      // window.alert("waiting for txn to be mined");
      props.updateStatus("loading");
      props.updateMsg("");
      const status = await waiting_for_txn(txn);
      if (status && status.includes("0x")) {
        // window.alert("success!");
        props.updateStatus("success");
        props.updateMsg(totalPrice + " " + numNfts + " " + status);
      } else if (status === "timeout") {
        // window.alert("txn timeout/stuck/pending for a while");
        props.updateMsg("Transaction might be in a Mempool somewhere!");
      } else {
        // window.alert(`fail! Reason:${status}`);
        console.log("fail", status);
        props.updateStatus("fail");
        props.updateMsg("Something went wrong!");
      }
    }
    // }
  }

  function printStatus(numNfts: number) {
    //get the title
    var title = "";
    for (var status of mintData["options"].sort((a, b) => a.nfts - b.nfts)) {
      if (numNfts >= status.nfts) {
        title = status.title;
      }
    }
    if (!title) return "";
    else {
      //get emojis
      var emojis = mintData["options"].filter(
        (entry) => entry.title === title
      )[0]["emoji"];
      return (
        "What " +
        (["a", "e", "i", "o", "u", "A", "E", "I", "O", "U"].includes(title[0])
          ? "an "
          : "a ") +
        title +
        "! " +
        emojis
      );
    }
  }

  return (
    <>
      <Translucent onClick={() => props.closeHandler()} />
      <Container>
        <ModalContainer
          style={{
            animation: "slideIn 0.5s",
          }}
        >
          <TitleBar>
            <Title>Mint</Title>
            <Para>
              {whitelisted
                ? mintData["whitelisted-description"]
                : mintData["description"]}
            </Para>
          </TitleBar>
          {isMobile ? (
            <MobileUnsupportedMsg>
              Sorry! Minting is not supported on phones. Please try again from a
              valid browser that supports Metamask.
            </MobileUnsupportedMsg>
          ) : (
            <Body>
              <MintCharContainer>
                {mintData["options"]
                  .sort((a, b) => a.nfts - b.nfts)
                  .map((entry, index) => (
                    <MintChar
                      title={entry.title}
                      value={roundToDp(entry.nfts * info.mintPrice)}
                      description={entry.description}
                      key={index}
                      setTotalAmt={() => {
                        setNumNfts(entry.nfts);
                      }}
                      selected={numNfts >= entry.nfts}
                    />
                  ))}
              </MintCharContainer>
              <CustomMintContainer>
                <CustomMintInputContainer>
                  <FiPlus className="cursor-pointer" onClick={plusHandler} />
                  <CustomMintInput
                    type={"number"}
                    placeholder="Add custom amount of NFTs"
                    onChange={(e) =>
                      setNumNfts(Math.min(100, Math.max(0, +e.target.value)))
                    }
                    value={numNfts === 0 ? "" : numNfts}
                    max={info.maxMintPerTx}
                    min={MINNFTS}
                  />
                  <FiMinus className="cursor-pointer" onClick={minusHandler} />
                </CustomMintInputContainer>
              </CustomMintContainer>
              {numNfts !== 0 && (
                <TotalEth style={{ background: "var(--color-rainbow)" }}>
                  WOW! You are donating {roundToDp(getTotalCost(numNfts))} ETH
                  to support public goods! {printStatus(numNfts)}
                </TotalEth>
              )}
              <OrderSummary>
                <OrderSection>
                  <OrderStat>Subtotal</OrderStat>
                  <OrderStat>
                    <div className="text-lg">
                      {roundToDp(getTotalCost(numNfts))}
                    </div>
                    <div className="text-sm">
                      ({info.mintPrice} Eth x {numNfts})
                    </div>
                  </OrderStat>
                </OrderSection>
                <OrderSection>
                  <OrderStat>Whitelist Discount</OrderStat>
                  <OrderStat>
                    <div className="text-lg">
                      {" "}
                      {roundToDp(getDiscount(numNfts)[0])} Eth
                    </div>
                    <div className="text-sm">
                      (0.05 Eth x {roundToDp(getDiscount(numNfts)[1])})
                    </div>
                  </OrderStat>
                </OrderSection>
                <OrderSection>
                  <OrderStat>
                    <b>Total</b>
                  </OrderStat>
                  <OrderStat className="text-lg">
                    <b>
                      {roundToDp(
                        getTotalCost(numNfts) - getDiscount(numNfts)[0]
                      )}{" "}
                      Eth
                    </b>
                  </OrderStat>
                </OrderSection>
              </OrderSummary>
              <ButtonContainer>
                <Button onClick={() => props.closeHandler()}>Back</Button>
                <ButtonColored onClick={submitMint}>
                  {minting ? (
                    <div className="flex justify-center items-center">
                      <svg className="h-6 w-6 animate-spin" viewBox="3 3 18 18">
                        <path
                          className="fill-gray-200"
                          d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                        />
                        <path
                          className="fill-gray-800"
                          d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                        />
                      </svg>
                    </div>
                  ) : (
                    "Mint"
                  )}
                </ButtonColored>
              </ButtonContainer>
            </Body>
          )}
        </ModalContainer>
      </Container>
    </>
  );
}

export default MintModal;
