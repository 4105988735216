import tw from "twin.macro";

interface TeamInfoData {
  name: string;
  title?: string;
  details: string[];
  img: string;
}

const Container = tw.div`
    px-6
    py-4
    font-body
    bg-white
    rounded-lg
    text-primaryBlack
    flex
    flex-col
    md:flex-row
    justify-center
    md:justify-start
    items-center
    md:items-start
    gap-8
`;

const ImageContainer = tw.div`
    w-40
    h-40
    rounded-full
    object-contain
`;

const Image = tw.img`
  rounded-full
`;

const Title = tw.h1`
    font-body_bold
    font-bold
    pb-1
    text-center
    md:text-left
`;

const Subtitle = tw.h5`
  pb-1
  text-sm
`;

const InfoContainer = tw.div`
    flex
    flex-col
    w-full
    md:w-2/3
`;

const InfoList = tw.ul`
    ml-4
`;

const Description = tw.li`
  py-2
`;

export function TeamInfo(props: TeamInfoData) {
  const { name, title, details, img } = props;
  return (
    <Container>
      <ImageContainer>
        <Image src={require("../../assets/" + img)} />
      </ImageContainer>
      <InfoContainer>
        <Title>{name}</Title>
        {title && <Subtitle>{title}</Subtitle>}
        <InfoList>
          {details.map((detail) => (
            <Description key={detail}>{detail}</Description>
          ))}
        </InfoList>
      </InfoContainer>
    </Container>
  );
}
