import { useEffect, useState } from "react";
import tw from "twin.macro";
import { Link, useNavigate } from "react-router-dom";
import HomeData from "./home-data.json";
import HomeBGLogo from "../../assets/home_bg_logo.webp";
import ComicPicBig1 from "../../assets/comic_pic_big_1.png";
import ComicPicBig2 from "../../assets/comic_pic_big_2.png";
import ComicPicBig3 from "../../assets/comic_pic_big_3.png";
import ComicPicBig4 from "../../assets/comic_pic_big_4.png";
import ComicPicSmall4 from "../../assets/comic_pic_small_4.png";
import PrefacePic1 from "../../assets/home_preface_pic_1.png";
import PrefacePic2 from "../../assets/home_preface_pic_2.png";
import PrefacePic3 from "../../assets/home_preface_pic_3.png";
import MintPic from "../../assets/home_mint.png";
import {
  cccStoreContract,
  getCurrentWalletConnected,
  loadInfo,
} from "../Mint/interact";
import TimerComp from "../Timer";
import { BsVolumeDown, BsVolumeMute, BsChevronDown } from "react-icons/bs";
import { animateScroll, scroller } from "react-scroll";
import ProgressBar from "../ProgressBar";
import gitcoin from "../../assets/gitcoin_icon.svg";
import electus_films from "../../assets/electus_films_icon.svg";
import { ethers } from "ethers";
import { useMediaQuery } from "react-responsive";
import { NavBar } from "../NavBar";
import { Footer } from "../Footer";

// Main components
const Container = tw.div`
  flex
  flex-col
  bg-primaryBlack
  font-sans
`;

const Header = tw.div`
  absolute
  top-0
  w-full
  z-20
`;

const Title = tw.p`
  text-3xl
  font-bold
  text-primaryYellow
  font-header
  py-6
  whitespace-pre-line
`;

const Para = tw.p`
  text-base
  text-primaryWhite
  font-body
  whitespace-pre-line
`;

const Button = tw(Para)`
  bg-primaryYellow
  text-black
  font-medium
  p-2
  m-auto
  flex
  items-center
  justify-center
  rounded-lg
  font-header
  cursor-pointer
`;

const Pic = tw.img`
  object-contain
  m-auto
`;

const Section = tw.div`
  p-6
  pb-0
  2xl:w-3/4
  3xl:w-1/2
  mx-auto
  flex
`;

const Icon = tw.div`
  bg-transparent
  z-10
  cursor-pointer
  m-2
  hover:scale-110
`;

// First visual, background and icons
const BG = tw.div`
  h-screen
  w-full
  flex
  flex-col
  items-center
`;

const BGLogo = tw(Pic)`
  h-2/3
  w-2/3
`;

const MuteIcon = tw(Icon)`
  fixed
  top-0
  z-0
  text-primaryWhite
`;

const NextIcon = tw(Icon)`
  bg-white/50
  absolute
  bottom-0
  rounded-full
  p-3
  mb-5
  left-[50%]
  -translate-x-2/4
`;

const Banner = tw.div`
  flex
  justify-center
  items-center
  bg-primaryYellow
  w-full
  h-9
  mb-5
  z-10
  italic
`;

const TagLineContent = tw.div`
  text-primaryBlack
  font-header
  text-[10px]
  md:text-base
  text-center
  px-10
`;

// Section 1
const Section1 = tw(Section)`
  flex-col
  justify-center
  items-center
  px-[15%]
  text-center
  py-10
`;

const Section1Para = tw(Para)`
  text-sm
`;

const Section1Pics = tw.div`
  flex
  items-center
  justify-center
  flex-row
  mt-10
  w-full
  md:w-3/4
`;

const Section1Pic = tw.img`
  w-16
  md:w-32
  m-auto
`;

// Section 2 - Comic strip
const ComicStrip = tw(Section)`
  w-full
  flex-row
  flex-wrap
  px-10
`;

const ComicTile = tw.div`
  w-full
  p-2
  flex
  justify-center
  md:justify-end
  items-end
`;

const ComicTileHalf = tw.div`
  w-full
  md:w-1/2
  flex
  p-2
  justify-between
  gap-4
`;

const ComicImg = tw.img`
  w-full
  border-4
  border-primaryWhite
`;

const ComicButton = tw(Button)`
  absolute
  m-5
  hover:scale-105
  hover:rotate-2
`;

const Section3 = tw(Section)`
  bg-gradient-to-b md:bg-gradient-to-r from-primaryYellow to-[#DF7F0E]
  w-full
  my-10
  p-0
  pt-4
  flex-col
  md:flex-row
`;

const Section3SubTitle = tw(Para)`
  text-black
  font-bold
  text-sm
`;

const Section3Title = tw(Title)`
  text-black
  text-5xl
`;

const Section3Button = tw(Button)`
  bg-[rgba(255, 255, 255, 0.4)]
  hover:bg-primaryGreen
  w-max
  rounded-3xl
  px-6
  text-xl
  text-primaryGreen
  hover:text-primaryWhite
  m-auto
  md:m-0
`;

const Section3Left = tw.div`
  flex-1
  py-5
  px-10
  text-center
  md:text-left
`;
const Section3Right = tw.div`
  flex-1
  flex
  justify-end
  items-end
`;

const Section3Pic = tw.img`
`;

// Section 4
const Section4 = tw(Section)`
  flex-col
  justify-center
  items-center
  text-center
`;

const Section4GridSection = tw.div`
  flex
  flex-row
  flex-wrap
  gap-4
  justify-center
`;

const Section4SubTitle = tw(Title)`
  text-2xl
`;

const Section4Grid = tw.div`
  w-full
  md:w-3/12
  bg-primaryWhite
  rounded-lg
  p-4
  pt-0
  text-center
`;

const Section4Para = tw(Para)`
  text-primaryBlack
`;

const ClosingMessage = tw(Title)`
  py-14
`;

// Sponsors section
const Sponsors = tw(Section)`
  bg-transparent
  border-4
  border-primaryWhite
  rounded-2xl
  w-3/4
  p-2
  flex
  justify-center
  items-center
  flex-col
  md:flex-row
  md:divide-x-2
  md:divide-y-0
  divide-y-2
`;

const Sponsor = tw.div`
  w-full
  flex
  flex-1
  justify-center
  items-center
  p-2
  text-primaryWhite
  font-body
`;

const SponsorIcon = tw.img`
  w-10
  mr-5
`;

export function Home() {
  // state variables
  const navigate = useNavigate();
  const [fundsRaised, setFundsRaised] = useState(0);
  const [audio, setAudio] = useState(new Audio());
  const [playing, setPlaying] = useState(false);
  const [eachMintPrice, setEachMintPrice] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  // constants
  const totalProgressBarValue = 1000;
  const timerTargetDate = 1656043199000;

  // called only once
  useEffect(() => {
    const asyncWrapper = async () => {
      //get current funds raised
      // const { address, provider } = cccStoreContract;
      // const intVal = await provider.getBalance(address);
      const intVal = await cccStoreContract.totalETHDonated();
      const ethVal = parseFloat(ethers.utils.formatEther(intVal));
      console.log(ethVal);
      setFundsRaised(ethVal);

      const { address: address2, status } = await getCurrentWalletConnected();
      const info_ = await loadInfo(address2);
      setEachMintPrice(info_.mintPrice);

      //set audio
      const music = new Audio(require("../../assets/background_audio.wav"));
      setAudio(music);
    };
    asyncWrapper();
  }, []);

  //to loop audio
  useEffect(() => {
    audio.addEventListener("ended", () => {
      audio.currentTime = 0;
      audio.play();
    });
  }, [audio]);

  const scrollToPreface = async () => {
    await navigate("/");
    await scroller.scrollTo("Preface", {
      smooth: "easeInOutQuint",
      duration: 1500,
    });
    setPlaying(true);
    audio.play();
  };

  function scrollToTop() {
    animateScroll.scrollToTop({
      smooth: "easeInOutQuint",
      duration: 1000,
    });
  }

  return (
    <Container>
      <Header>
        <Banner>
          <TagLineContent>{HomeData.top_tag_line}</TagLineContent>
        </Banner>
        <NavBar socials />
      </Header>
      <MuteIcon>
        {playing ? (
          <BsVolumeDown
            onClick={() => {
              setPlaying(false);
              audio.pause();
            }}
            size={30}
          />
        ) : (
          <BsVolumeMute
            onClick={() => {
              setPlaying(true);
              audio.play();
            }}
            size={30}
          />
        )}
      </MuteIcon>
      <NextIcon onClick={scrollToPreface}>
        <BsChevronDown size={20} />
      </NextIcon>
      <div
        className="
        bg-cover
        bg-top
        bg-[url('../src/assets/home_bg_0_small.jpg')]
        lg:bg-[url('../src/assets/home_bg_0_big.jpg')]
      "
      >
        <BG>
          <BGLogo src={HomeBGLogo}></BGLogo>
        </BG>
      </div>
      <Section1 id="Preface">
        <Section1Para>{HomeData.title_1_0}</Section1Para>
        <Title>{HomeData.title_1_1}</Title>
        <Para>{HomeData.para_1_1}</Para>
        <Title>{HomeData.title_1_2}</Title>
        <Para>{HomeData.para_1_2}</Para>
        <Section1Pics>
          <Section1Pic src={PrefacePic1} />
          <Section1Pic src={PrefacePic2} />
          <Section1Pic src={PrefacePic3} />
        </Section1Pics>
      </Section1>
      <ComicStrip id="Lore">
        <ComicTileHalf style={{ flexDirection: "column" }}>
          <ComicImg src={ComicPicBig1} />
          {!isMobile && <ComicImg src={ComicPicBig2} />}
        </ComicTileHalf>
        <ComicTileHalf>
          <ComicImg src={ComicPicBig3} />
        </ComicTileHalf>
        {isMobile && (
          <ComicTileHalf style={{ flexDirection: "column" }}>
            <ComicImg src={ComicPicBig2} />
          </ComicTileHalf>
        )}
        <ComicTile>
          <ComicImg src={isMobile ? ComicPicSmall4 : ComicPicBig4} />
          <ComicButton>
            <Link to="/">FULL STORY COMING SOON...</Link>
          </ComicButton>
        </ComicTile>
      </ComicStrip>
      {/* <Section3>
        <Title>{HomeData.title_3}</Title>
        <Para>{HomeData.para_3}</Para>
        <br />
        <br />
        <TimerComp epoch={timerTargetDate} />
        <br />
        <Section3Button>
          <a href="https://gitcoin.co/grants/explorer/">
            DONATE TO GET WHITELISTED
          </a>
        </Section3Button>
      </Section3> */}
      <Section3>
        <Section3Left>
          <Section3SubTitle>
            MINT A PIECE OF CRYPTO HISTORY NOW!
          </Section3SubTitle>
          <Section3Title>
            Fund crypto public goods and get awesome NFTs!
          </Section3Title>
          <Section3Button>
            <Link to="/mint" onClick={scrollToTop}>
              MINT @ {eachMintPrice} ETH
            </Link>
          </Section3Button>
        </Section3Left>
        <Section3Right>
          <Section3Pic src={MintPic} />
        </Section3Right>
      </Section3>
      <Section4 id="Roadmap">
        <Title>{HomeData.title_4}</Title>
        <Para>{HomeData.para_4}</Para>
        <ProgressBar current={fundsRaised} total={totalProgressBarValue} />
        <br />
        <Section4GridSection>
          <Section4Grid>
            <Section4SubTitle>{HomeData.milestone_1_title}</Section4SubTitle>
            <Section4Para>{HomeData.milestone_1_content}</Section4Para>
          </Section4Grid>
          <Section4Grid>
            <Section4SubTitle>{HomeData.milestone_2_title}</Section4SubTitle>
            <Section4Para>{HomeData.milestone_2_content}</Section4Para>
          </Section4Grid>
          <Section4Grid>
            <Section4SubTitle>{HomeData.milestone_3_title}</Section4SubTitle>
            <Section4Para>{HomeData.milestone_3_content}</Section4Para>
          </Section4Grid>
        </Section4GridSection>
        <ClosingMessage>{HomeData.closing_message}</ClosingMessage>
      </Section4>
      <Sponsors>
        <Sponsor>
          <SponsorIcon src={electus_films} />
          <div>
            VIDEO SPONSOR <br /> Electus Films
          </div>
        </Sponsor>
        <Sponsor>
          <SponsorIcon src={gitcoin} />
          <div>
            IN SUPPORT OF <br /> Gitcoin
          </div>
        </Sponsor>
      </Sponsors>
      <Footer />
    </Container>
  );
}
