import tw from "twin.macro";
import TeamData from "./team-data.json";
import { NavBar } from "../NavBar";
import { BsPeople } from "react-icons/bs";
import { TeamInfo } from "./TeamInfo";
import React from "react";

const Container = tw.div`
  bg-primaryBlack
  min-h-screen
  w-full
`;

const Section = tw.div`
  mx-auto
  2xl:w-3/4
  3xl:w-1/2
  flex
  flex-col
  md:flex-row
  bg-primaryBlack
  font-sans
  py-10
  px-6
`;

const SectionLeft = tw.div`
  flex
  flex-col
  justify-start
  items-center
  text-center
  text-primaryYellow
  md:w-1/3
  md:items-start
  md:text-left
  md:sticky
  md:top-5
  md:h-full
`;

const SectionRight = tw.div`
  text-primaryWhite
  w-full
  flex
  flex-col
  md:w-2/3
  gap-4
`;

const Title = tw.h1`
  text-3xl
  text-primaryYellow
  font-header
  py-6
`;

const SubHeader = tw.h3`
  text-xl
  text-primaryWhite
  font-header
  text-center
  md:text-left
`;

export function Team() {
  return (
    <Container>
      <NavBar blueLogo />
      <Section>
        <SectionLeft>
          <BsPeople size={50} />
          <Title>The Team</Title>
        </SectionLeft>
        <SectionRight>
          {Object.entries(TeamData).map(([title, info]) => (
            <React.Fragment key={title}>
              <SubHeader>{title}</SubHeader>
              {info.map((info) => (
                <TeamInfo
                  key={info.name}
                  name={info.name}
                  title={info.title}
                  details={info.details}
                  img={info.img}
                />
              ))}
            </React.Fragment>
          ))}
        </SectionRight>
      </Section>
    </Container>
  );
}
