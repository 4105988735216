import tw from "twin.macro";
import { BsTwitter, BsCheck2Circle } from "react-icons/bs";
import { BiError } from "react-icons/bi";

interface StatusModalProps {
  status: String;
  message?: String;
  closeHandler: () => void;
}

const Translucent = tw.div`
    fixed
    overflow-x-hidden
    bg-gray-600
    bg-opacity-50
    backdrop-blur-sm
    h-screen
    w-screen  
    z-20
`;

const Container = tw.div`
    mx-auto
    2xl:w-3/4
    3xl:w-1/2
    flex 
    justify-end
    h-full
    relative
    select-none
`;

const ModalContainer = tw.div`
    bg-primaryWhite
    animate-[slideIn]
    z-20
    fixed
    top-1/2
    right-1/2
    overflow-y-auto
    p-6
    translate-x-1/2
    -translate-y-1/2
    flex
    flex-col
    justify-center
    items-center
    gap-4
    w-[400px]
`;

const Para = tw.p`
    font-body
    text-sm
    whitespace-pre-line
    underline
    cursor-pointer
`;

const Message = tw(Para)`
    no-underline
`;

const Title = tw.h1`
    font-header
    text-lg
`;

const Button = tw.button`
    w-full
    border-black
    border-2
    text-primaryBlack
    p-2
    font-body
    rounded-lg
`;

const ButtonColored = tw(Button)`
    text-primaryWhite
    bg-blue-500
    border-none
    flex
    flex-row
    justify-center
    items-center
    gap-2
`;

function StatusModal(props: StatusModalProps) {
  if (props.status === "success") {
    return (
      <>
        <Translucent onClick={props.closeHandler} />
        <Container>
          <ModalContainer>
            <Title>Transaction Sucessful</Title>
            <BsCheck2Circle size={52} />
            <ButtonColored>
              <BsTwitter />
              <a
                href={`https://twitter.com/intent/tweet?text=I%20just%20donated%20${
                  Math.round(+(props.message?.split(" ")[0] || 0) * 100) / 100
                }%20ETH%20and%20minted%20${
                  props.message?.split(" ")[1]
                }%20%40ccc_nft%20NFT(s)%20to%20support%20crypto%20public%20goods%20and%20%40gitcoin%20%23CosmicCryptoCrusade%20%0A%0AJoin%20me%20in%20minting%20one%20today%20and%20get%20an%20awesome%20NFT%20while%20doing%20good%20for%20the%20crypto%20ecosystem!`}
              >
                Tweet about how amazing you are!
              </a>
            </ButtonColored>
            <Button onClick={props.closeHandler}>Close</Button>
            <Para>
              <a
                href={`https://etherscan.io/tx/${
                  props.message?.split(" ")[2]
                }`}
              >
                View on Etherscan!
              </a>
            </Para>
          </ModalContainer>
        </Container>
      </>
    );
  }
  if (props.status === "fail") {
    return (
      <>
        <Translucent onClick={props.closeHandler} />
        <Container>
          <ModalContainer>
            <Title>Transaction Failed</Title>
            {props.message && <Message>{props.message}</Message>}
            <BiError color={"red"} size={52} />
            <Button onClick={props.closeHandler}>Close</Button>
          </ModalContainer>
        </Container>
      </>
    );
  }
  if (props.status === "loading") {
    return (
      <>
        <Translucent onClick={props.closeHandler} />
        <Container>
          <ModalContainer>
            <Title>Transaction still pending</Title>
            <div className="flex justify-center items-center">
              <svg className="h-6 w-6 animate-spin" viewBox="3 3 18 18">
                <path
                  className="fill-gray-200"
                  d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                />
                <path
                  className="fill-gray-800"
                  d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"
                />
              </svg>
            </div>
            {props.message && <Message>{props.message}</Message>}
            <Button onClick={props.closeHandler}>Close</Button>
          </ModalContainer>
        </Container>
      </>
    );
  } else {
    return <></>;
  }
}

export default StatusModal;
